<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
        <h1>Vendas</h1>
      </v-flex>

      <v-flex xs12 md4 sm12>
        <CardTotalFaturado />
      </v-flex>

      <v-flex xs12 md4 sm12>
        <CardVendasRealizadas />
      </v-flex>

      <v-flex xs12 md4 sm12>
        <CardComissaoAcumulada />
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 sm12 md12>
        <h1>Ranking</h1>
      </v-flex>
      <v-flex xs12 md6 sm12>
        <RankingPlano />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const CardTotalFaturado = () => import('./Card/CardTotalFaturado')
const CardVendasRealizadas = () => import('./Card/CardVendasRealizadas')
const CardComissaoAcumulada = () => import('./Card/CardComissaoAcumulada')

const RankingPlano = () => import('./Chart/RankingPlano')

export default {
  name: 'Dashboard',
  components: {
    CardTotalFaturado,
    CardVendasRealizadas,
    CardComissaoAcumulada,
    RankingPlano
  },
  data () {
    return {

    }
  }
}

</script>

<style scoped>
</style>